import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import { isActive} from "../utils/Utils"


import "../assets/css/header.css"

const KnActiveLink = ( props ) => ( 
	<Link to={props.to} id={ props.id} getProps={isActive} {...props} />
)

const Header = ({ siteTitle }) => (
	<header className="kn-mlinar-igra-header">
		<div className="kn-mlinar-igra-header-column">
			<Link to="/">
				<StaticImage
			  src="../assets/images/logo-mlinar.svg"
			  quality={100}
			  formats={["auto", "webp", "avif"]}
			  alt="Mlinar logo"
			/>
			</Link>
		</div>
		<div className="kn-mlinar-igra-header-column">
			<KnActiveLink to="/pravila-nagradne-igre">
				Pravila nagradne igre
			</KnActiveLink>
		</div>
	</header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
